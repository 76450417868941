
:root {
  --red-color: #dc332e;
  --red-gradient: linear-gradient(94.83deg, #dc332e -1.9%, #bf181d 99.19%);
  --heading-black-color: #181818;
  --text-black-color: #333333;
  --white-color: #fff;
  --black-color: #000;
  --border-radius-1: 10px;

  --trans: all 0.3s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.card-border-radius {
  border-radius: var(--border-radius-1);
}

/* ===============BACKGROUND COLORS================== */
.bg-red-gradient {
  background: linear-gradient(94.83deg, #dc332e -1.9%, #bf181d 99.19%);
}

.bg-red-color {
  background-color: var(--red-color);
}

/* ===============FONT COLORS================== */
.text-black {
  color: var(--black-color);
}

.text-heading-black {
  color: var(--heading-black-color);
}

.text-black-1 {
  color: var(--text-black-color);
}

.text-white {
  color: var(--white-color);
}

.text-red {
  color: var(--red-color);
}

/* ===============FONT SIZES================== */
.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 0.88rem;
}

.fs-15 {
  font-size: 0.94rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

/* ===============FONT WEIGHTS================== */
.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.white-btn {
  font-size: 16px;
  line-height: 24px;
  background: var(--white-color);
  border-radius: 80px;
  padding: 13px 60px;
  color: var(--red-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  border: 0px;
}

.btn-btn {
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(94.83deg, #dc332e -1.9%, #dc332e 99.19%);
  border-radius: 80px;
  padding: 13px 60px;
  color: var(--white-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  border: 0px;
}
.btn-btn:hover {
  color: #dc332e;
  background: #fff;
}
.btn-btn:active {
  color: var(--white-color);
  background: linear-gradient(94.83deg, #dc332e -1.9%, #dc332e 99.19%);
}

.red-btn {
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(94.83deg, #dc332e -1.9%, #bf181d 99.19%);
  border-radius: 80px;
  padding: 13px 60px;
  color: var(--white-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  border: 0px;
}

.red-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.red-btn :active {
  color: var(--white-color);
}

.product-btn-red {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background: #dc332e;
  border-radius: 9px;
  padding: 17px 80px;
  border: 2px solid #dc332e;
  text-decoration: none;
}

.product-btn-red:hover {
  color: #fff;
}

.product-btn-white:hover {
  color: #dc332e;
}

.product-btn-white {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #dc332e;
  background: #fff;
  border: 2px solid #dc332e;
  border-radius: 9px;
  padding: 17px 80px;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a,
a:hover,
a:focus {
  color: #333;
  text-decoration: none;
  transition: all 0.3s;
}

ul,
li {
  text-decoration: none;
  list-style: none;
}
.pb-28 {
  padding-bottom: 28px;
}


/* Topbar css */

.topbar {
  padding: 22px 0;
}

.topbar ul {
  margin-bottom: 0;
  padding-left: 0;
}

.topbar ul li {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 0 12px;
}

.topbar ul li i {
  width: 25px;
  height: 25px;
  background-color: var(--white-color);
  color: var(--red-color);
  text-align: center;
  border-radius: 25px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 800;
}

/* Header Css */
/* header {
  background: url(../src/assets/images/header-bg-n.png);
} */

header .navbar {
  padding: 24px 0;
}

header .navbar-nav .nav-item .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: var(--black-color);
  text-align: center;
  cursor: pointer;
}

header .navbar-nav .nav-item .nav-link.active {
  font-weight: 600;
  cursor: pointer;
}

header .navbar-nav .nav-item {
  margin: 0 16px;
}

header .navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.header-container {
  padding: 12px 0;
  background-color: #f5f5f5;
}

.header-container .header-right li.nav-item {
  margin: 0px 10px;
}

.header-container .header-right li.nav-item a {
  font-size: 14px;
  font-weight: 400;
  color: var(--black-color);
}

.header-container .header-right li.nav-item .nav-link:hover {
  color: var(--red-color);
}

.header-container .header-right li.nav-item .active {
  color: var(--red-color);
  font-weight: 600;
}

/* Footer Css */
.footer {
  background: url(../src/assets/images/footer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 106px 0 70px 0;
  position: relative;
  /* margin-top: auto; */
}

.footer i {
  width: 25px;
  height: 25px;
  background-color: var(--white-color);
  color: var(--red-color);
  text-align: center;
  border-radius: 25px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 800;
}

.footer h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  color: var(--white-color);
}

.footer ul {
  padding-left: 0;
}

.footer li {
  margin: 13px 0;
}

.footer li a {
  font-size: 14px;
  font-weight: 400;
  color: var(--white-color);
  line-height: 28px;
}

.footer-col-one ul li {
  margin: 25px 0;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  /* display: flex; */
}

.footer-col-one ul li i {
  margin-right: 10px;
}

.footer .footer-col-one img {
  position: absolute;
  top: 0;
  padding: 2px;
  background-color: var(--white-color);
  height: 20%;
}

@media screen and (max-width: 769px) {
  .footer .footer-col-one img {
    position: absolute;
    top: 0;
    padding: 2px;
    background-color: var(--white-color);
    width: 50%;
    height: 15%;
  }
}
@media screen and (max-width: 550px) {
  .footer .footer-col-one img {
    position: absolute;
    top: 0;
    padding: 2px;
    background-color: var(--white-color);
    width: 50%;
    height: 10%;
  }
}
.footer-col-four ul li {
  margin-right: 25px;
}



/*************DownLoad Section***********/
.mobile-device {
  padding: 0px !important;
}
.mobile-device {
  background: linear-gradient(
    95.48deg,
    #f5f7fa 0.23%,
    rgba(245, 247, 250, 0) 100%
  ) !important;
  padding: 10px !important;
}

.section-header {
  text-align: center;
  padding-bottom: 40px;
  /* margin-top: -48px; */
}
.section-header span {
  color: #db2228;
}

.section-header p {
  margin: 10px 0 0 0;
  font-style: normal !important;
  font-weight: 600;
  font-size: 32px;
  color: #1b1f23;
  line-height: 38px;
  width: 100%;
}


/* ************ABOUT US******** */
.container-list{
  color: #fff;
  min-width: 50%;
  min-height: 10vh;
  padding: 30px;
  padding-left: 10rem;
}
.container-header{
  display: flex;
  /* align-items: center;
  justify-content: center;
  padding: 40px; */
}
.container-text{
  padding-left: 95px;
    padding-top: 95px;
    font-size: 28px;
}
.container-text-two{
  padding-left: 250px;
  padding-right: 100px;
  line-height: 2;
  padding-top: 95px;
}


/****Why choose me******/

/* WHYCHOOSE SECTION CSS */
#whyChooseSection .whyChooseSectionWrapper {
  padding: 70px 0;
  background: url(../src/assets/images/why-choose-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#whyChooseSection .topHeading h3 {
  font-weight: 300;
  font-size: 34px;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 70px;
}

#whyChooseSection .whyChooseCard {
  padding: 38px 18px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 300px;
  margin: 0 5px;
}

#whyChooseSection .whyChooseCard img {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

#whyChooseSection .whyChooseCard h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-color);
  letter-spacing: 0.02em;
  text-align: left;
}

#whyChooseSection .whyChooseCard p {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: rgba(79, 79, 79, 0.8);
  letter-spacing: 0.02em;
  text-align: left;
}

/************ Responsive design ***************/

@media screen and (max-width: 992px) {
  #whyChooseSection .whyChooseCard {
    margin-bottom: 32px;
    min-height: 300px;
  }


  .container-header{
    flex-direction: column;
  }
  
  .container-text{
    padding-left: 30px;
     
  }
  .topbar {
    display: none;
  }
  .footer {
    padding: 106px 12px 60px 12px;
  }
  .fs-md-10 {
    font-size: 10px;
  }

  .fs-md-11 {
    font-size: 11px;
  }

  .fs-md-12 {
    font-size: 12px;
  }

  .fs-md-13 {
    font-size: 13px;
  }

  .fs-md-14 {
    font-size: 0.88rem;
  }

  .fs-md-15 {
    font-size: 0.94rem;
  }

  .fs-md-16 {
    font-size: 1rem;
  }

  .fs-md-17 {
    font-size: 17px;
  }

  .fs-md-18 {
    font-size: 18px;
  }

  .fs-md-19 {
    font-size: 19px;
  }

  .fs-md-20 {
    font-size: 20px;
  }

  .fs-md-22 {
    font-size: 22px;
  }

  .fs-md-24 {
    font-size: 24px;
  }

  .fs-md-26 {
    font-size: 26px;
  }

  .fs-md-28 {
    font-size: 28px;
  }

  .fs-md-30 {
    font-size: 30px;
  }

  /* ===============FONT WEIGHTS================== */
  .fw-md-2 {
    font-weight: 200;
  }

  .fw-md-3 {
    font-weight: 300;
  }

  .fw-md-4 {
    font-weight: 400;
  }

  .fw-md-5 {
    font-weight: 500;
  }

  .fw-md-6 {
    font-weight: 600;
  }

  .fw-md-7 {
    font-weight: 700;
  }

  .fw-md-8 {
    font-weight: 800;
  }


}
@media (max-width: 767px) {
  /*************** HOME PAGE RESONSIVE CSS **************/
  #heroSection .heroSectionWrapper {
    background-position: 10%;
  }

  #heroSection .heroSectionWrapper .leftContent {
    padding: 40px 0 75px 0;
  }

  #heroSection .leftContent h1 {
    text-align: center;
    font-weight: 800;
    font-size: 28px;
    line-height: 47px;
  }

  #heroSection .leftContent p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }

  #heroSection .leftContent .position-relative {
    text-align: center;
  }

  #heroSection .leftContent .white-btn {
    padding: 12px 28px;
    border-radius: 36px;
  }

  #heroSection .leftContent .bannerArrow img {
    max-width: 70%;
  }

  #heroSection .leftContent .bannerArrow {
    top: 25px;
    left: 210px;
  }

  #planSection .planSectionWrapper {
    padding: 70px 0;
  }

  #planSection .planHeader {
    margin-bottom: 30px;
  }

  #planSection .planHeader h2 {
    font-size: 25px;
    line-height: 38px;
    width: 100%;
  }

  #planSection .planHeader p {
    font-size: 14px;
    line-height: 30px;
  }

  #planSection .planHeader p br {
    display: none;
  }

  #planSection .planCardWrapper .planCard {
    min-height: 430px;
    margin-bottom: 25px;
  }

  #planSection .planCard .planCardHeader p {
    font-size: 15px;
  }

  #planSection .planCard .planCardHeader span {
    font-size: 8px;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper {
    padding: 0;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox:last-child {
    flex-grow: 1;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox p {
    font-size: 13px;
  }

  #planSection .planCard .planCardBody ul {
    margin-top: 8px;
  }

  #planSection .planCard .planCardBody ul li {
    font-size: 12px;
    line-height: 20px;
    padding: 8px 0;
  }

  #planSection .planCard .planCardBody ul li img {
    width: 14px;
    height: 14px;
  }

  #planSection .planCard .planCardBody .priceWrapper {
    margin: 24px 0;
  }

  #planSection .planCard .planCardBody .priceWrapper .percent p {
    font-size: 20px;
    padding: 10px 0;
  }

  #planSection .planCard .planCardBody .priceWrapper .price p {
    font-size: 14px;
    padding: 10px 0;
  }

  #planSection .planCard .planCardFooter a {
    font-size: 12px;
  }

  #planSection .planCard .planCardFooter button {
    font-size: 12px;
    padding: 8px 35px;
  }

  #pincodeSection .leftContent p {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 30px;
  }

  #pincodeSection .rightContent .pincodeInput > img {
    padding: 13px 10px 13px 10px;
  }

  #quizSection .quizSectionWrapper {
    padding: 70px 0;
  }

  #quizSection .quizSectionWrapper .leftContent {
    text-align: center;
    margin-bottom: 40px;
  }

  #quizSection .quizSectionWrapper .leftContent img {
    max-width: 80%;
  }

  #quizSection .quizSectionWrapper .rightContent h3 {
    font-size: 25px;
    line-height: 38px;
    font-weight: 400;
  }

  #quizSection .quizSectionWrapper .rightContent h3 span br {
    display: none;
  }

  #quizSection .quizSectionWrapper .rightContent p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  #quizSection .quizSectionWrapper .rightContent a.red-btn {
    padding: 13px 53px;
  }

  #qualitySection .qualitySectionWrapper {
    padding: 70px 0;
  }

  #qualitySection .qualitySectionWrapper .leftContent h3 {
    font-size: 25px;
    line-height: 38px;
    font-weight: 400;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper {
    margin-top: 20px;
    justify-content: space-between;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box {
    flex-basis: 48%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box h4 {
    margin-bottom: 0;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box p {
    font-size: 10px;
  }

  #qualitySection .qualitySectionWrapper .rightContent {
    padding-bottom: 0;
    text-align: center;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent {
    min-height: 250px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent {
    min-height: 250px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table {
    top: 40px;
    left: 10px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table tr th,
  #healthwaySection .healthwaySectionWrapper .leftContent table tr td {
    font-size: 8px;
    padding: 4px 8px 4px 8px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    padding: 25px 10px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper h4 {
    margin-bottom: 0;
    font-size: 14px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper p {
    font-size: 9px;
    padding: 10px 10px;
    margin: 20px 0;
    line-height: 12px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .rightContent
    .contentWrapper
    .red-btn {
    font-size: 12px;
    padding: 5px 20px;
  }

  #whyChooseSection .whyChooseSectionWrapper {
    padding: 70px 0 40px 0;
  }

  #whyChooseSection .topHeading h3 {
    font-size: 25px;
    line-height: 48px;
  }

  #whyChooseSection .whyChooseCard {
    padding: 20px 10px;
    text-align: left;
    min-height: 185px;
    margin: 0;
    margin-bottom: 32px;
  }

  #whyChooseSection .whyChooseCard.marginCard {
    margin-top: -95px;
  }

  #whyChooseSection .whyChooseCard img {
    width: 45px;
    height: 45px;
    margin-bottom: 8px;
  }

  #whyChooseSection .whyChooseCard h4 {
    font-size: 11px;
    line-height: 15px;
  }

  #whyChooseSection .whyChooseCard p {
    font-size: 9px;
    line-height: 13px;
  }

  #newsSection .newsSectionWrapper {
    padding: 70px 0;
  }

  #newsSection .newsLogo {
    padding: 8px 20px;
  }

  #newsSection .newsHeader h2 {
    font-size: 25px;
    line-height: 38px;
  }

  #newsSection .newsHeader p {
    font-size: 16px;
    line-height: 29px;
  }

  #newsSection .newsHeader p br {
    display: none;
  }

  #testimonialSection .testimonialCard {
    min-height: 345px;
  }

  #testimonialSection .testimonialCard .cardTop img {
    width: 60px;
    height: 60px;
  }

  #testimonialSection .testimonialCard .cardBody p {
    font-size: 12px;
    line-height: 21px;
  }

  #testimonialSection .testimonialHeader h2 {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 30px;
  }

  #faqSection .faqSectionWrapper,
  #faqPageSection .faqSectionWrapper {
    padding: 70px 0;
  }

  #faqSection .faqHeader h2,
  #faqPageSection .faqHeader h2 {
    font-size: 25px;
    line-height: 38px;
  }

  #faqSection .accordion-button:not(.collapsed),
  #faqPageSection .accordion-button:not(.collapsed) {
    font-size: 14px !important;
    line-height: 24px;
  }

  #faqSection .accordion-button,
  #faqPageSection .accordion-button {
    font-size: 14px !important;
    line-height: 24px;
    padding: 10px 5px;
  }

  #faqSection .accordion-body p,
  #faqPageSection .accordion-body p {
    font-size: 12px;
    line-height: 20px;
  }

  #faqSection .accordion-body,
  #faqPageSection .accordion-body {
    margin: 0;
    margin-bottom: 10px;
  }

  #faqSection .faqContentWrapper .accordion,
  #faqPageSection .faqContentWrapper .accordion {
    padding: 15px;
  }

  #faqSection .faqContentWrapper {
    margin: 35px 0;
  }

  #callBackSection .callBackSectionWrapper {
    margin: 0;
  }

  #callBackSection .callBackSectionWrapper .container {
    padding: 0;
  }

  #callBackSection .callBackWrapper {
    padding: 70px 20px;
  }

  #callBackSection .callBackHeader h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  #callBackSection .callBackHeader p {
    font-size: 16px;
  }

  #callBackSection .callBackHeader {
    margin-bottom: 40px;
  }

  #callBackSection .formWrapper .form-control {
    font-size: 14px;
    padding: 10px 5px 10px 0;
  }

  #callBackSection .fieldWrapper {
    margin-bottom: 25px;
  }

  #footerSection .footerRightWrapper {
    width: 100%;
    padding: 33px 5px 25px 5px;
  }

  #footerSection .footerRightWrapper .top h3 {
    font-size: 25px;
    line-height: 48px;
  }

  #footerSection .footerRightWrapper .bottom p {
    font-size: 14px;
    line-height: 28px;
  }

  #footerSection .footerLeftWrapper {
    padding: 50px 0 60px 0;
    width: 100%;
    margin: 0 5px;
  }

  #footerSection .footerLeftWrapper .leftContent p {
    width: 70%;
    margin-right: auto;
  }

  #footerSection .footerLeftWrapper .rightContent ul {
    padding-left: 0;
  }

  #footerSection .copyright {
    width: 100%;
    text-align: center;
  }

  /*************** STEPS PAGES RESONSIVE CSS **************/
  .stepWrapper .stepWrapperBox {
    padding: 70px 20px 70px 20px;
    border-radius: 0;
  }

  .stepWrapper .stepWrapperBox .stepHeader h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .stepWrapper .stepWrapperBox .stepHeader p {
    font-size: 14px;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard {
    margin: 0 5px 22px 5px;
    padding: 20px 7px 10px 7px;
    min-height: 115px;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard .image {
    height: 55px;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard p {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard p span {
    font-size: 8px;
    line-height: 11px;
  }

  .stepWrapper .stepWrapperBox .stepBody {
    min-height: 425px;
  }

  .stepWrapper .stepWrapperBox .stepFooter .btnWrapper .backBtn a {
    width: 160px;
    padding: 12px 0;
    font-size: 14px;
  }

  .stepWrapper .stepWrapperBox .stepFooter .btnWrapper .nextBtn a {
    width: 160px;
    padding: 12px 0;
    font-size: 14px;
  }

  .stepWrapper .stepBody .ratingWrapper .ratingScale span {
    font-size: 12px;
    padding: 8px 4px;
  }

  .stepWrapper .stepBody .formWrapper .form-label {
    font-size: 20px;
  }

  .stepWrapper .stepBody .formWrapper .fieldWrapper .row .col {
    padding: 0 5px;
  }

  .stepWrapper .stepBody .formWrapper .fieldWrapper .genderRadio,
  .stepWrapper .stepBody .formWrapper .fieldWrapper .bmi {
    width: 140px;
  }

  .step7Wrapper .stepHeader img {
    transform: scale(1.1);
  }

  .step7Wrapper .stepHeader {
    overflow: hidden;
    padding-bottom: 20px;
  }

  .step7Wrapper .stepWrapperBox .stepBody {
    padding: 40px 20px 0 20px;
    min-height: 380px !important;
  }

  .step7Wrapper .stepBody h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .step7Wrapper .stepFooter {
    padding: 0 20px 30px 20px;
  }

  .step7Wrapper .textMobile {
    padding: 0 20px 20px 20px;
  }

  .step7Wrapper .textMobile p {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 0;
  }

  #healthSection .healthSectionWrapper {
    padding: 40px 0;
  }

  #healthSection .leftContent {
    text-align: center;
  }

  #healthSection .leftContent img {
    max-width: 80%;
  }

  #healthSection .rightContent .healthCard {
    background: #f9f9f9;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 40px 20px;
  }

  #healthSection .rightContent .healthCard .healthCardHeader p {
    font-size: 15px;
    padding-bottom: 0;
  }

  #healthSection .healthCard .healthCardBody .iconBoxWrapper {
    padding: 0;
    justify-content: space-between;
  }

  #healthSection .healthCard .healthCardBody .iconBoxWrapper .iconBox p {
    font-size: 13px;
  }

  #healthSection .healthCard .healthCardBody ul li img {
    width: 14px;
    height: 14px;
  }

  #healthSection .healthCard .healthCardBody .btnWrapper {
    flex-direction: column;
    margin-top: 25px;
  }

  #healthSection .healthCard .healthCardBody .btnWrapper .priceWrapper {
    width: 100%;
    margin-bottom: 21px;
    min-height: 50px;
  }

  #healthSection .healthCard .healthCardBody .priceWrapper .price p br {
    display: none;
  }

  #healthSection .healthCard .healthCardBody .btnContent {
    width: 100%;
  }

  #bloodTestSection .bloodTestSectionWrapper {
    padding: 70px 0;
  }

  #bloodTestSection .bloodTestHeader h2 {
    margin-bottom: 30px;
  }

  #bloodTestSection .bloodTestCard .cardBody p {
    margin: 20px 0 20px 0;
  }

  #bloodTestSection .bloodTestCard .cardBody .tagsWrapper .tags span {
    font-size: 12px;
  }

  #bloodTestSection .bloodTestCard {
    min-height: 270px;
  }

  #faqPageSection .faqHeader h3 {
    margin-top: 30px;
  }
}


