.spanSpace{
    font-size: 1rem;
    font-weight: bold;
    margin-right: 6px;
}
.paragraphAlignment{
    text-align: justify;
    padding-left: 20px;
    padding-bottom: 30px;
    font-family: inherit;
}
.heading{
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: inherit;
    padding-bottom: 30px;
    margin-top: 30px;
    color: rgba(0,0,0,1);
    text-align: Left !important;
}

